export const OrderCreatePostAllocation_TransactionFieldNames: string[] = [
  'id',
  'qty',
  'price',
  'tradeTime',
  'dealDate',
  'status',
  'statusReason',
  'type',
  'subType',
  'transactionNumber',
  'portfolioCcy',
  'order.id',
  'bankAccount.id',
  'bankAccount.name',
  'bankAccount.multyCcy',
  'bankAccount.baClientType',
  'portfolio.id',
  'portfolio.name',
  'portfolio.account.id',
  'portfolio.account.name',
  'portfolio.mandate.authorizedPersons.id',
  'portfolio.mandate.authorizedPersons.main',
  'portfolio.mandate.authorizedPersons.client.id',
  'portfolio.mandate.authorizedPersons.client.name',
  'portfolio.company.exPerson.id',
  'instrument.id',
  'instrument.ccy',
  'instrument.name',
];

export const OrderCreateFxPostAllocation_TransactionFieldNames: string[] = [
  'id',
  'qty',
  'commission',
  'tradeTime',
  'status',
  'statusReason',
  'type',
  'subType',
  'transactionNumber',
  'multileg',
  'parent.id',
  'order.id',
  'bankAccount.id',
  'bankAccount.baCompType',
  'portfolio.id',
  'instrument.id',
  'instrument.ccy',
];

export const ProductProposal_OrderFieldNames = [
  'id',
  'status',
  'ccy',
  'qty',
  'bankAccount.id',
  'bankAccount.name',
  'bankAccount.account.name',
  'product.id',
  'product.name',
  'product.ccy',
  'product.entryFee',
  'portfolio.name',
];

export const ProposalForClient_OrderFieldNames = [
  'id',
  'status',
  'qty',
  'ccy',
  'product.id',
  'product.name',
  'product.view.backgroundVertical',
];

export const ProposalForManager_OrderFieldNames = [
  'portfolio.name',
  'id',
  'status',
  'qty',
  'orderClient.name',
  'initiatorManager.name',
  'product.id',
  'product.name',
  'product.background',
  'product.view.backgroundVertical',
];

export const OrderCreate_StrategyTinyFieldNames: string[] = [
  'id',
  'name',
  'ccy',
  'multyCcy',
  'background',
];

export const OrderCreate_StrategyFieldNames: string[] = [
  'id',
  'name',
  'ccy',
  'multyCcy',
  'background',
  'interval',
  'minIncrement',
  'minInvestYear',
  'maxInvestment',
  'maxInvestYear',
  'minimumInvestment',
  'rateOfRisk',
  'entryFee',
  'managementFee',
  'successFee',
];

export const OrderCreate_AlternativeInvestmentFieldNames: string[] = [
  'id',
  'externalId',
  'Name',
  'Currency',
  'assetType',
  'Start Date',
  'End Date',
  'Rate',
];

export const OrderCreateFxProduct_PositionFieldNames: string[] = [
  'product.id',
  'product.name',
  'product.background',
];

export const PrivateAssets_PositionFieldNames = [
  'instrument.id',
  'instrument.name',
  'instrument.icon',
  'portfolio.account.id',
  'portfolio.id',
  'portfolio.name',
  'baseCcy',
  'valueBaseCcy',
  'totalPnlBaseCcy',
  'totalPnlPctBaseCcy',
  'assetType',
];

export const OrderStrategyPositions_PositionFieldNames = [
  'qty',
  'qtyAvailable',
  'instrument.id',
  'instrument.assetType',
];

export const RelatedOrdersFieldNames: string[] = [
  'id',
  'number',
  'qty',
  'executedQty',
  'status',
  'orderInitiator',
  'price',
  'ccy',
  'bankAccount.id',
  'bankAccount.name',
  'bankAccount.baCompType',
  'bankAccount.custodian.bank',
  'portfolio.id',
  'portfolio.name',
  'initiatorManager.id',
  'initiatorManager.name',
  'orderClient.id',
  'orderClient.name',
  'transferFrom.custodian.bank',
  'transferFrom.name',
  'transferFrom.id',
  'transferFrom.baCompType',
  'transferFrom.baClientType',
  'transferTo.custodian.bank',
  'transferTo.baClientType',
  'transferTo.baCompType',
  'transferTo.name',
  'transferTo.id',
];

export const PortfolioCreate_AccountFieldNames: string[] = ['id', 'name', 'companyAccount'];

export const OrderCreate_PortfolioFieldNames: string[] = [
  'id',
  'portfolio.name',
  'portfolio.ccy',
  'portfolio.type',
  'portfolio.mandate.id',
  'portfolio.company.id',
  'portfolio.portfolioManagers.id',
  'portfolio.bankAccounts.main',
  'portfolio.bankAccounts.bankAccount.id',
  'portfolio.bankAccounts.bankAccount.name',
  'portfolio.bankAccounts.bankAccount.baCompType',
];

export const GlobalAllocation_PortfolioFieldNames: string[] = [
  'id',
  'portfolio.id',
  'portfolio.name',
  'portfolio.account.id',
  'portfolio.account.name',
  'portfolio.globalAllocation.id',
];

export const TransactionDialog_PositionFieldNames: string[] = [
  'qty',
  'instrument.id',
  'instrument.name',
  'instrument.icon',
  'instrument.assetType',
  'portfolio.bankAccounts.bankAccount.id',
];

export const PrivateInstrumentSelector_PositionFieldNames: string[] = [
  'qty',
  'instrument.id',
  'instrument.name',
  'instrument.icon',
  'instrument.assetType',
  'instrument.assetSubType',
  'portfolio.id',
  'portfolio.name',
  'portfolio.bankAccounts.main',
  'portfolio.bankAccounts.bankAccount.id',
  'portfolio.bankAccounts.bankAccount.name',
];

export const TransactionDialog_TransactionFieldNames: string[] = [
  'id',
  'portfolio.id',
  'portfolio.name',
];

export const PortfolioTinyFieldNames = ['id', 'name'];

export const BankAccountSelector_PortfolioFieldNames: string[] = [
  'id',
  'portfolio.bankAccounts.main',
  'portfolio.bankAccounts.bankAccount.id',
  'portfolio.bankAccounts.bankAccount.name',
  'portfolio.bankAccounts.bankAccount.account.name',
  'portfolio.bankAccounts.bankAccount.custodian.name',
  'portfolio.bankAccounts.bankAccount.custodian.bank',
];

export const BankAccountSelector_BankAccountFieldNames: string[] = [
  'id',
  'name',
  'account.name',
  'custodian.name',
  'custodian.bank',
];

export const OrderCreateToStrategyPostAllocationDialog_TransactionFieldNames: string[] = [
  'id',
  'netAmountPortfolioCcy',
  'type',
  'subType',
  'tradeTime',
  'valueDate',
  'portfolio.id',
  'portfolio.ccy',
  'portfolio.bankAccounts.bankAccount.id',
  'portfolio.bankAccounts.bankAccount.name',
  'portfolio.bankAccounts.bankAccount.baCompType',
  'instrument.id',
  'instrument.name',
  'instrument.ccy',
  'instrument.isin',
  'instrument.bloombergId',
];

export const OrderCreateToStrategyPostAllocationDialog_PositionFieldNames: string[] = [
  'id',
  'qty',
  'instrumentId',
  'product.id',
  'product.name',
  'product.background',
  'portfolio.bankAccounts.main',
  'portfolio.bankAccounts.bankAccount.id',
  'portfolio.bankAccounts.bankAccount.name',
];

export const GlobalAllocationWorkflow_GlobalAllocationTemplateFieldNames: string[] = [
  'id',
  'name',
  'description',
];

export const BankAccountSelector_PositionFieldNames = [
  'id',
  'qtyAvailable',
  'fx',
  'ccy',
  'baseCcy',
  'portfolio.bankAccounts.bankAccount.id',
  'portfolio.bankAccounts.bankAccount.name',
  'portfolio.bankAccounts.bankAccount.ccy',
  'portfolio.bankAccounts.bankAccount.multyCcy',
  'portfolio.bankAccounts.bankAccount.custodian.bank',
];

export const ConsolidatedPortfolioCreate_PortfolioFieldNames: string[] = [
  'portfolio.id',
  'portfolio.name',
  'portfolio.account.id',
  'portfolio.account.name',
  'portfolio.company.name',
  'portfolio.company.id',
];

export const PositionGroupFields = {
  BankAccountId: 'portfolio.bankAccounts.bankAccount.id',
};
