<div #label
     class="app-text-truncate">
  <ng-content></ng-content>
</div>

<div #labelTest
     class="app-clipped-text-tooltip__test">
</div>

<ng-template #tooltipTemplate>
  <div class="app-clipped-text-tooltip__tooltip"
       [style.top.px]="hostRect.top + hostRect.height"
       [style.left.px]="hostRect.left"
       @tooltipAnimation>
    {{ message }}
  </div>
</ng-template>